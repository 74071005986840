import { createApp } from 'vue';
import Amplify from 'aws-amplify';
import Varlet from '@varlet/ui';
import App from './App.vue';
import router from './router';
import store from './store';
import '@varlet/ui/es/style';
import '@/assets/css/ravenvr.scss';

import awsconfig from './aws-exports.ts';

Amplify.configure(awsconfig);

const app = createApp(App);

app.use(Varlet);
app.use(router);
app.use(store);

router.isReady().then(() => {
  app.mount('#app');
});
