import { Auth } from 'aws-amplify';

class ResponseError extends Error {
  constructor(message, statusCode) {
    super(message);
    this.statusCode = statusCode;

    this.name = this.constructor.name;
    Error.captureStackTrace(this, this.constructor);
  }
}

export async function request(url, method, token, data) {
  // Don't define Content-Type header for multipart/form-data
  // AWS lambda will store header in event['headers']['content-type']
  const settings = {
    method,
    headers: {
      Authorization: token,
    },
  };

  if (data) {
    settings.body = data;
  }

  let response;
  try {
    response = await fetch(url, settings);
  } catch (err) {
    console.error(err);

    const error = new Error('Unable to send request');
    error.code = '-1';

    throw error;
  }

  if (!response.ok) {
    const res = await response.text();

    const message = res.message || res;

    throw new ResponseError(message, response.status);
  }
  return response.text();
}

export async function uploadToS3(url, file) {
  try {
    const settings = {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Length': new Blob([file]).size,
      },
    };
    const response = await fetch(url, settings);

    return response.text();
  } catch (err) {
    console.error(err);

    const error = new Error('Unable to send request');
    error.code = '-1';

    throw error;
  }
}

export async function getUserAuthToken() {
  let token = null;

  // Do an auth call to force a token refresh if current token has expired
  await Auth.currentSession();

  const user = await Auth.currentAuthenticatedUser();
  token = user.signInUserSession.idToken.jwtToken;

  return token;
}
