import { createStore } from 'vuex';
import wellgroups from './modules/wellgroups';

const store = createStore({
  modules: {
    wellgroups,
  },
  strict: true,
});

export default store;
