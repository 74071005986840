/* eslint-disable import/extensions */
import { request, getUserAuthToken } from '@ravenvr/utils/utils.js';

const getters = {
  getWellgroups: (state) => {
    const wellgroupsSorted = state.wellgroups.slice();
    wellgroupsSorted.sort((a, b) => a.displayName - b.displayName);
    return wellgroupsSorted;
  },
  getWellgroupFolders: (state) => {
    const sortedFolders = state.folders.slice().sort((a, b) => a.name.localeCompare(b.name));

    return sortedFolders;
  },
};

const actions = {
  async fetchWellgroups({ commit }) {
    commit('SET_LOADING', { operation: 'read', loading: true });
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/wellgroups`;
      const token = await getUserAuthToken();
      const result = await request(url, 'GET', token);
      const wellgroups = JSON.parse(result);

      wellgroups.forEach((wellgroup, index) => {
        let numWellsWithCoords = 0;
        wellgroup.wells.forEach((well) => {
          if (well.topSurfaceLatitude && well.topSurfaceLongitude) {
            numWellsWithCoords += 1;
          }
        });

        wellgroups[index].numWellsWithCoords = numWellsWithCoords;
      });

      commit('SET_WELLGROUPS', wellgroups);
    } catch (err) {
      commit('SET_ERROR', err);
      console.error(`Unable to retrieve list of well groups: ${err}`);
    }
    commit('SET_LOADING', { operation: 'read', loading: false });
  },
  async fetchWellgroupFolders({ commit }) {
    commit('SET_LOADING', { operation: 'read', loading: true });
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/wellgroupfolders`;
      const token = await getUserAuthToken();
      const result = await request(url, 'GET', token);
      const wellgroups = JSON.parse(result);
      commit('SET_WELLGROUP_FOLDERS', wellgroups);
    } catch (err) {
      commit('SET_ERROR', err);
      console.error(`Unable to retrieve well groups folders: ${err}`);
    }
    commit('SET_LOADING', { operation: 'read', loading: false });
  },
  async viewWellgroup({ commit }, { wellgroup }) {
    commit('SET_LOADING', { operation: 'read', loading: true });
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/wellgroups/${wellgroup.id}/url`;
      const token = await getUserAuthToken();
      const result = await request(url, 'GET', token);
      const json = JSON.parse(result);
      const arg1 = encodeURIComponent(json.signedUrl);
      const arg2 = encodeURIComponent(wellgroup.displayName);
      console.log(`${process.env.VUE_APP_UNITY_APP_URL}/index.html?arg1=${arg1}&arg2=${arg2}`);
      window.open(`${process.env.VUE_APP_UNITY_APP_URL}/index.html?arg1=${arg1}&arg2=${arg2}`, '_blank');
    } catch (err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', { operation: 'read', loading: false });
  },
};

const mutations = {
  SET_WELLGROUPS(state, wellgroups) {
    state.wellgroups = wellgroups;
  },
  SET_WELLGROUP_FOLDERS(state, folders) {
    state.folders = folders;
  },
  SET_ERROR(state, error) {
    state.status.error = error;
  },
  SET_LOADING(state, { operation, loading }) {
    state.status.loading[operation] = loading;
  },
};

const state = () => ({
  wellgroups: [],
  folders: [],
  status: {
    error: null,
    loading: {
      read: false,
    },
  },
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
