<template>
  <authenticator :hide-sign-up="true">
    <template v-slot:header>
      <div class="authenticator-title">
        <h1>Blackstone RavenVR Client Login</h1>
      </div>
    </template>
    <var-app-bar title="RavenVR Client Dashboard" title-position="center">
      <template #left>
        <div class="logo-container">
          <img src="images/blackstone-drilling-logo.svg" alt="Blackstone Drilling"/>
        </div>
      </template>
      <template #right>
        <var-cell class="appbar-clientname">
          {{ clientName }}
        </var-cell>
        <var-menu v-model:show="showMenu" :offset-y="40" :offset-x="-45">
          <var-button round text color="transparent" @click="showMenu = true">
            <var-icon name="menu" :size="24"/>
          </var-button>
          <template #menu>
            <div>
              <var-cell class="appbar-menu__cell" v-ripple @click="signOut">
                Sign Out
              </var-cell>
              <var-cell class="appbar-menu__cell" v-ripple @click="about">
                About
              </var-cell>
            </div>
          </template>
        </var-menu>
      </template>
    </var-app-bar>
    <var-dialog v-model:show="showAbout" title="About"
        :cancelButton=false
        confirmButtonText="OK">
      Version: {{ version }}
    </var-dialog>
    <div class="app-content">
      <var-space class="container" direction="column" space="large">
        <router-view @user="setClientName"></router-view>
      </var-space>
    </div>
  </authenticator>
</template>

<script setup>
import { Authenticator } from '@aws-amplify/ui-vue';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-vue/styles.css';
import { ref } from 'vue';

const showMenu = ref(false);
const clientName = ref(null);
const showAbout = ref(false);
const version = ref(process.env.APP_VERSION);

function signOut() {
  showMenu.value = false;
  Auth.signOut();
}

function setClientName(name) {
  clientName.value = name;
}

function about() {
  showAbout.value = true;
}

</script>

<style lang="scss">
@import './assets/css/_colors.module';

/* Override Amplify min-height */
body {
  min-height: 50vh;
}

.container {
  margin: 1% 5%;
}

.authenticator-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-secondary;
}

.appbar-clientname {
  color: $color-secondary !important;
}

.appbar-menu__cell {
  background-color: white;
  cursor: pointer;
}

.logo-container {
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: $color-primary;
  height: var(--app-bar-height);
  width: 316px;

  img {
    width: 300px;
    height: 54px;
  }
}

/* Amplify Authenticator style overrides */
.amplify-button[data-variation='primary'] {
  background-color: $color-primary;
}

.amplify-button[data-variation='primary']:hover {
  background-color: #1d2127;
}

[data-amplify-authenticator] {
  --amplify-components-button-link-color: #1d2127;
  --amplify-components-button-link-color-hover: #1d2127;
  --amplify-components-button-link-hover-background-color: white;
}

/* Override Varlet app bar to make fixed */
.var-app-bar {
  position: fixed !important;
  top: 0;
  z-index: 1000 !important;
}

/* Accommodate fixed app bar */
.app-content {
  margin-top: 54px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}
</style>
